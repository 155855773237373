import React from "react";

import PropTypes from "prop-types";

import OutlineButton2 from "./outline-button2";
import "./place-card2.css";

const PlaceCard2 = (props) => {
  return (
    <div className="place-card2-container">
      <img
        alt={props.image_alt}
        src={props.image}
        className="place-card2-image"
      />
      <div className="place-card2-container1">
        <span className="place-card2-text">{props.city}</span>
        <span className="place-card2-text1">{props.description}</span>
      </div>
    </div>
  );
};

PlaceCard2.defaultProps = {
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.",
  city: "City Name",
  image_alt: "image",
  image:
    "https://images.unsplash.com/photo-1514924013411-cbf25faa35bb?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&w=1000",
};

PlaceCard2.propTypes = {
  description: PropTypes.string,
  city: PropTypes.string,
  image_alt: PropTypes.string,
  image: PropTypes.string,
};

export default PlaceCard2;
