import React from 'react'

import PropTypes from 'prop-types'

import './outline-button111.css'

const OutlineButton111 = (props) => {
  return (
    <div className="outline-button111-container">
      <button className="outline-button111-button button Button">
        {props.button1}
      </button>
    </div>
  )
}

OutlineButton111.defaultProps = {
  button1: 'Button',
}

OutlineButton111.propTypes = {
  button1: PropTypes.string,
}

export default OutlineButton111
